import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2';
import Header from './Header';
import SideNavbar from './SideNavbar';
import HomePage from './HomePage';
import Footer from './Footer';
import AdminList from './AdminComponents/AdminList';
import AddAdmin from './AdminComponents/AddAdmin';
import Login from './Login';
import EventList from './EventComponent/EventList';
import AddEvent from './EventComponent/AddEvent';

export default class Container extends Component {
  constructor(props) {
    super(props)

    this.state = {
      Aname: "",
    }
  }

  async componentDidMount() {
    var number = "";
    number = JSON.parse(sessionStorage.getItem('Mobile'));
    const sendData = {
      Mobile: number,
      type : "details",
    }
   
    if (number) {
      await axios.post("/Login.php",sendData)
        .then((response) => {
          if (response.data.status === "Invalid") {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong! User is invalid ',
              footer: '<a href="">Why do I have this issue?</a>'

            })
              .then((result) => {
                if (result.isConfirmed) {
                  window.location.replace("/");
                }
              })
          }

          else if (response.data.status === "Valid") {
            this.setState({
              Aname: response.data.Name,
            });
            sessionStorage.setItem('Mobile', JSON.stringify(number));
          }

          else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong & Data not found for user!',

            })
              .then((result) => {
                if (result.isConfirmed) {
                  window.location.replace("/");
                }
              })
          }

        })
        .catch((error) => {
          alert(error.message);
        });

    }

  }

  LoginHandler = async(event)=>{
    event.preventDefault();
    const mobile = event.target.Mobile.value;
    const pass = event.target.Pass.value;

    const sendData = {
      Mobile: mobile,
      Password: pass,
    }

    await axios.post("/Login.php", sendData)
           .then((response) => {
             if (response.data.status === "Invalid") {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter correct credentials',
              })
             }
             else if(response.data.status === "Valid")
            {
              Swal.fire({
                title: "Success!",
                text: "Login Successfull at Haidery Masjid - Admin Access !",
                icon: "success",
              })
                .then((result) => {
                  if (result.isConfirmed) {
                    this.setState({
                      aname : response.data.phpresult["Name"],
                    });
                    sessionStorage.setItem('Mobile',JSON.stringify(mobile));
                    window.location.replace('/Dashboard');
                  }
                })

              
            }
            else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong or Data not found!',
  
              })
            }
            })
  }
  
  addAdminHandler = async (event) => {
    event.preventDefault();
    const Name = event.target.aname.value;
    const its = event.target.aits.value;
    const password = event.target.apwd.value;
    const area = event.target.aarea.value;

    const sendData = {
      Aname: Name,
      AITS: its,
      Aarea: area,
      Apassword: password,
      Type : "AreaAdmin",
    }

     await axios.post("/Add.php", sendData)
      .then((response) => {
    
        if (response.data.data.Status === "Invalid") {

          Swal.fire({
            title: "Oops !! ",
            text: "An Error Occurred !! Try after some time",
            icon: "error",
            button: "Back",
          });
        }

        else if (response.data.data.Status === "Valid") {
          Swal.fire({
            title: "Success!",
            text: "Admin Added Successfully !",
            icon: "success",
          })
            .then((result) => {
              if (result.isConfirmed) {
                window.location.replace('/Admin');
              }
            })

        }
      })
      .catch((error) => {
          Swal.fire({
            title: "Oops !! ",
            text: "Some Error Occurred !!",
            icon: "error",
            button: "Back",
          });
        
      });

  }

  render() {
    return (
      <>
      
      <Router>
        <div class="main-wrapper">
         
          <Routes>

          <Route path="/" element={
              <>
               <Login />
               <Footer></Footer>
              </>
            } />

            <Route path="/Dashboard" element={
              <>
               <Header Name={this.state.Aname}></Header>
                <SideNavbar pathname="Home"></SideNavbar>
                <div class="page-wrapper">
                  <HomePage Name={this.state.Aname}></HomePage>
                  <Footer></Footer>
                </div>
              </>
            } />

            <Route path="/Admin" element={
              <>
               <Header Name={this.state.Aname}></Header>
                <SideNavbar pathname="Admin"></SideNavbar>
                <div class="page-wrapper">
                  <AdminList></AdminList>
                  <Footer></Footer>
                </div>
              </>
            } />

            <Route path="/AddAdmin" element={
              <>
               <Header Name={this.state.Aname}></Header>
                <SideNavbar pathname="Admin"></SideNavbar>
                <div class="page-wrapper">
                  <AddAdmin AddAdmin={this.addAdminHandler} />
                  <Footer></Footer>
                </div>
              </>
            } />

              <Route path="/Events" element={
              <>
               <Header Name={this.state.Aname}></Header>
                <SideNavbar pathname="Event"></SideNavbar>
                <div class="page-wrapper">
                 <EventList />
                  <Footer></Footer>
                </div>
              </>
            } />

              <Route path="/CreateEvent" element={
              <>
               <Header Name={this.state.Aname}></Header>
                <SideNavbar pathname="Event"></SideNavbar>
                <div class="page-wrapper">
                 <AddEvent />
                  <Footer></Footer>
                </div>
              </>
            } />
             
              <Route path="*" element={
              <>
               <p>Page not Found !!</p>
              </>
            } />

          </Routes>
        </div>


      </Router>

      </>
    )
  }
}
