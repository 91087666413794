import React, { Component } from 'react';
import { Link} from 'react-router-dom';

export default class SideNavbar extends Component {
	constructor(props) {
	  super(props)
	
	  this.state = {}
	}

  render() {
    return (
      <div>
		

		<div class="sidebar" id="sidebar">
                <div class="sidebar-inner slimscroll">
					<div id="sidebar-menu" class="sidebar-menu">
						<ul>
							<li class="menu-title"> 
								<span>Main Menu</span>
							</li>
							<li class= {this.props.pathname === "Home" ? "active" : ""}> 
								<Link to="/Dashboard"><i class="fas fa-th-large"></i> <span>Dashboard</span></Link>
							</li>

							
							<li class= {this.props.pathname === "Admin" ? "active" : ""}> 
							<Link to="/Admin"><i class="fas fa-chalkboard-teacher"></i> <span>Area Admin Management</span></Link>
							</li>
							
							<li class= {this.props.pathname === "Event" ? "active" : ""}> 
							<Link to="/Events"><i class="fas fa-user-check"></i> <span>Event Management</span></Link>
							</li>
							
						</ul>
					</div>
                </div>
				
      </div>
	  </div>
     
            
    )
  }
}
