import React, { Component } from 'react';
import { Link} from 'react-router-dom';
import Container from '../Container';
import $ from 'jquery'; 
import Swal from 'sweetalert2';

export default class AddAdmin extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        isClicked : false,
        btnText : "Add Area Admin",
      }

    }

    propchange = (event)=> {
        event.preventDefault();
        this.setState({isClicked:!this.state.isClicked, btnText : "Processing "});
            setTimeout(()=>{
            new Container().addAdminHandler(event);
            this.setState({isClicked:!this.state.isClicked, btnText : "Add Area Admin "});
        },1000);
        
    }

    
  render() {
    return (
    <div className="content container-fluid">
				
        
        <div className="page-header">
            <div className="row align-items-center">
                <div className="col">
                    <h3 className="page-title">Add Area Admin</h3>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/Admin">Admin List</Link></li>
                        <li className="breadcrumb-item active">Add Area Admin</li>
                    </ul>
                </div>
            </div>
        </div>
        
    
        <div className="row">
            <div className="col-sm-12">
            
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={this.propchange} id='AdminForm'>
                            <div className="row">
                                <div className="col-12">
                                    <h5 className="form-title"><span>Admin Information</span></h5>
                                </div>
                                <div className="col-12 col-sm-6">  
                                    <div className="form-group">
                                        <label>Full Name*</label>
                                        <input type="text" className="form-control" id='aname' name='aname'required={true}/>
                                    </div>
                                </div>
                                
                                <div className="col-12">
                                    <h5 className="form-title"><span>Credential Setup</span></h5>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>ITS No*</label>
                                        <input type="number" className="form-control" name='aits' required={true}/>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Password*</label>
                                        <input type="password" className="form-control" name='apwd' required={true}/>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <h5 className="form-title"><span>Area Setup</span></h5>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Managing Area(For eg : Haidery Masjid - Bhopal)*</label>
                                        <input type="text" className="form-control" name='aarea' id='aarea' required={true} />
                                    </div>
                                </div>

                               
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary" disabled ={this.state.isClicked}>
                                    {this.state.isClicked
                                    ? <span className="spinner-border spinner-border-sm mr-2" role="status"></span>
                                    : ""
                                    }
                                    
                                    {this.state.btnText}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>							
            </div>					
        </div>					
    </div>	
    )
  }
}
