import Container from "./Components/Container";

function App() {
  return (
    <div>
      <Container></Container>
    </div>
  );
}

export default App;
