import React, { Component } from 'react'
import axios from "axios";
import Container from './Container';
import Swal from 'sweetalert2';

export default class Login extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        disabled : false
      }
    }

    componentDidMount(){
        const its = JSON.parse(sessionStorage.getItem('Mobile'));
        if (its !== null) {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Already Logged in ',
          })
            .then((result) => {
              if (result.isConfirmed) {
                window.location.replace('/Dashboard');
              }
            })
        }
      }

    buttonhandler = async (event) => {
        event.preventDefault();
        if (this.state.disabled) {
           return;
       }
       this.setState({disabled: true});
  
       setTimeout(()=>{
        new Container().LoginHandler(event);
        this.setState({disabled: false});
       },1000)
      }

  render() {
    return (
      <div>
        <div class="main-wrapper login-body">
            <div class="login-wrapper">
            	<div class="container">
                	<div class="loginbox">
                    	<div class="login-left">
							<img class="img-fluid" src="/assets/img/Logo_Bhopal_Jamaat.png" alt="Logo" />
                        </div>
                        <div class="login-right">
							<div class="login-right-wrap">
								<h1>Welcome to Haidery Majid, Bhopal - Admin Access</h1>
								<p class="account-subtitle">Anjuman-e-Mohammedi, Bhopal Jamaat, India.</p>
								<form onSubmit={this.buttonhandler}>
									<div class="form-group">
										<input class="form-control" type="text" placeholder="Mobile Number" required={true} name="Mobile" maxLength={10}/>
									</div>
									<div class="form-group">
										<input class="form-control" type="password" placeholder="Password" required={true} name="Pass"/>
									</div>
									<div class="form-group">
										<button class="btn btn-primary btn-block" type="submit" disabled={this.state.disabled}>{this.state.disabled ? 'Authenticating...' : 'Login'}</button>
									</div>
								</form>
								
								
						</div>	
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
  }
}
