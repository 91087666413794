import React, { Component } from 'react'
import { Link} from 'react-router-dom';
import Container from '../Container';
import $ from 'jquery'; 
import Swal from 'sweetalert2';
import axios from "axios";

export default class AddEvent extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
          isClicked : false,
          btnText : "Create Event",
          Vtype : null,
        }
        
      }

    SelectHandler = async() =>
    {
        var type = $('#evideo').val();
        this.setState({Vtype : type})
    }

    propchange = async (event)=> {
        event.preventDefault();
            this.setState({isClicked:!this.state.isClicked, btnText : "Processing "});

    const Etype = event.target.evideo.value;
    const EVisible = event.target.evisible.value;
    const EName = event.target.ename.value;
    const EDetails = event.target.edetails.value;
    var EValid = event.target.evalid.value;
    var EVideo = null;
    var ECode = null;
    if(Etype === "Youtube")
    {
      ECode = event.target.evideocode.value;
    }
    if(Etype === "RVideo")
    {
      EVideo = event.target.evideofile.value;
    }

    const sendData = {
      EVisible: EVisible,
      Etype: Etype,
      EName: EName,
      EDetails: EDetails,
      EVideo: EVideo,
      ECode: ECode,
      EValid: EValid,
    }
    await axios.post("/AddEvent.php", sendData
      , {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }

    )
    
    .then((response) => {
    this.setState({isClicked:!this.state.isClicked, btnText : "Create Event "});
      if (response.data.data.Status === "Invalid") {
        Swal.fire({
          title: "Oops !! ",
          text: "An Error Occurred !! Try after some time",
          icon: "error",
          button: "Back",
        });
      }

      else if (response.data.data.Status === "Valid") {
        Swal.fire({
          title: "Success!",
          text: "Event Added Successfully",
          icon: "success",
        })
          .then((result) => {
            if (result.isConfirmed) {
              window.location.replace('/Events');
            }
          })

      }

      else {
        Swal.fire({
          title: "Oops !! ",
          text: "Some Error Occurred !!",
          icon: "error",
          button: "Back",
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        title: "Oops !! ",
        text: "Some Error Occurred !!",
        icon: "error",
        button: "Back",
      });
    });
        
    }

  render() {
    return (
        <div className="content container-fluid">
				
        
        <div className="page-header">
            <div className="row align-items-center">
                <div className="col">
                    <h3 className="page-title">Create an Event</h3>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/Events">Event List</Link></li>
                        <li className="breadcrumb-item active">Add Event</li>
                    </ul>
                </div>
            </div>
        </div>
        
    
        <div className="row">
            <div className="col-sm-12">
            
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={this.propchange} id='AdminForm'>
                            <div className="row">
                                <div className="col-12">
                                    <h5 className="form-title"><span>Event Information</span></h5>
                                </div>
                                <div className="col-12 col-sm-6">  
                                    <div className="form-group">
                                        <label>Select Event Visibility*</label>
                                        <select name='evisible' className="form-control">
                                            <option value="Public">Public</option>
                                            <option value="Private">Private</option>
                                        </select>
                                        
                                    </div>
                                </div>


                                <div className="col-12 col-sm-6">  
                                    <div className="form-group">
                                        <label>Select Video Type*</label>
                                        <select name='evideo' id='evideo' className="form-control" onChange={this.SelectHandler}>
                                            <option value="RVideo">Recorded Video</option>
                                            <option value="Youtube">Youtube Video</option>
                                        </select>
                                        
                                    </div>
                                </div>
                                <div className="col-12">
                                    <h5 className="form-title"><span>Event Information</span></h5>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Event Name*</label>
                                        <input type="text" className="form-control" name='ename' required={true}/>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Event Details*</label>
                                        <input type="text" className="form-control" name='edetails' required={true}/>
                                    </div>
                                </div>
                                {this.state.Vtype && this.state.Vtype === "Youtube"
                                ?
                                <div className="col-12 col-sm-6">
                                <div className="form-group">
                                    <label>Enter Youtube Video Code*</label>
                                    <input type="text" className="form-control" name='evideocode'/>
                                </div>
                            </div>
                                :<div className="col-12 col-sm-6">
                                <div className="form-group">
                                    <label>VideoFile*</label>
                                    <input type="text" className="form-control" name='evideofile'/>
                                </div>
                            </div>}
                                
                            <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Valid Till (Format : 20-09-2022 23:59:00)*</label>
                                        <input type="text" placeholder='dd-mm-yyyy hh:mm:ss' className="form-control" name='evalid' required={true}/>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary" disabled ={this.state.isClicked}>
                                    {this.state.isClicked
                                    ? <span className="spinner-border spinner-border-sm mr-2" role="status"></span>
                                    : ""
                                    }
                                    
                                    {this.state.btnText}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>							
            </div>					
        </div>					
    </div> 
    )
  }
}
