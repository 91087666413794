import React, { Component } from 'react'
import axios from "axios";
import Swal from 'sweetalert2';

export default class HomePage extends Component {
    constructor(props) {
      super(props)
      this.state = {
        users : null,
      };

    }
    async componentDidMount()
      {
          const sendData = {
              Type: 'EventList',
            }
      await axios.post("/View.php", sendData)
      .then((response)=>{
          if(response.data.Status ==="Invalid")
          {
              Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Some error occurred while trying to send the list.',
                })
          }
          else
          {
              this.setState({users : response.data.phpresult});
          }
        
      })
      .catch((error)=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Some error occurred while trying to send the list.',
          })
      });
      
      }
  render() {
    return (

		<div class="content container-fluid">
            <div class="page-header">
                <div class="row">
                    <div class="col-sm-12">
                        <h3 class="page-title">Welcome {this.props.Name} !</h3>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item active">Admin Dashboard</li>
                        </ul>
                    </div>
                </div>
            </div>
           
 
            <div class="row">
                <div class="col-md-12 d-flex">						
        
                    <div class="card flex-fill">
                        <div class="card-header">
                            <h5 class="card-title">Active Events</h5>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-hover table-center">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>SNo</th>
                                            <th>Event Name</th>
                                            <th class="text-center">Valid Till</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.users && this.state.users.map((result,index) =>
                                        <tr>
                                        <td class="text-nowrap">
                                            <div>{index+1}</div>
                                        </td>
                                        <td>{result.Name}</td>
                                        <td class="text-center">{result.Valid}</td>
                                    </tr>
                                    )}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
        
                </div>

            </div>

            
        </div>
                
    
    )
  }
}
