import React, { Component } from 'react'
import axios from "axios";
import Swal from 'sweetalert2';
import { Link} from 'react-router-dom'

export default class EventList extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
          users : null,
        }
  
  
      }
      
      async componentDidMount()
      {
          const sendData = {
              Type: 'EventList',
            }
      await axios.post("/View.php", sendData)
      .then((response)=>{
          if(response.data.Status ==="Invalid")
          {
              Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Some error occurred while trying to send the list.',
                })
          }
          else
          {
              this.setState({users : response.data.phpresult});
          }
        
      })
      .catch((error)=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Some error occurred while trying to send the list.',
          })
      });
      
      }
  
      DeleteEvent = async(event) =>
    {
      event.preventDefault();
      const id = event.currentTarget.id;
      const sendData = {
        AId : id,
        Type : 'Event',
      }
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to recover this Event again !",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      })
      .then(async(result) => {
        if (result.isConfirmed) {
          await axios.post("/Delete.php",sendData)
          .then((response)=>{
            if(response.data.data.Status === "Invalid")
            {
              Swal.fire({
                title: "Oops !! ",
                text: "An Error Occurred !! Try after some time",
                icon: "error",
                button: "Back",
              });
            }
  
            else if(response.data.data.Status === "Valid")
        {
          Swal.fire({
            title: "Success!",
            text: "Event Deleted Successfully!",
            icon: "success",   
        })
        .then(async(result) => {
          if (result.isConfirmed) {
              const sendData = {
                  Type: 'EventList',
                }
              await axios.post("/View.php", sendData)
              .then((response)=>{
                this.setState({users : response.data.phpresult},()=>{
                  window.location.reload();
                });
              })
          } 
        })
        }
  
        else
        {
          Swal.fire({
            title: "Oops !! ",
            text: "Try after some time !!",
            icon: "error",
            button: "Back",
          });
        }
  
          })
        
        }
      })
  
    }
  render() {
    return (
      
        <div class="content container-fluid">
				
                
                <div class="page-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="page-title">Events</h3>
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li class="breadcrumb-item active">All Events</li>
                            </ul>
                        </div>
                        <div class="col-auto text-right float-right ml-auto">
                            <Link to="/CreateEvent" class="btn btn-primary"><i class="fas fa-plus"></i> Add Event</Link>
                        </div>
                    </div>
                </div>
                
            
                <div class="row">
                    <div class="col-sm-12">
                    
                        <div class="card card-table">
                            <div class="card-body">
                                <div class="table-responsive">
                                
                                <table class="table table-hover table-center mb-0 datatable" id="example">
											<thead>
												<tr>
                                                    <th>Actions</th>
                                                    <th>S.No</th>
													<th>Visbile</th>
                                                    <th>Event</th>
                                                    <th>Code/File</th>
                                                    <th>Created</th>
                                                    <th>Valid Till</th>
												</tr>
											</thead>
											<tbody>
                                            
                                            {this.state.users && this.state.users.map((result,index) =>
              <tr>
                <td>
                <div class="actions">
               
					<button class="btn btn-sm bg-danger" id={result.Id} link={result.Link} onClick={this.DeleteEvent}>
					<i class="fas fa-trash"></i>
					</button>
                    &nbsp;&nbsp;&nbsp;

                </div>
                </td>
                  <td key={index}>{index+1}</td>
                  <td><span className={result.Visibility === "Private" ? "badge badge-danger" : "badge badge-success"}>{result.Visibility}</span></td>
                  <td className='text-wrap'>{result.Name}</td>
                  <td className='text-wrap'>{result.Link}</td>
                  <td className='text-wrap'>{result.CreatedOn}</td>
                  <td className='text-wrap'>{result.Valid}</td>
                </tr>
          
          )}
											</tbody>
            </table>
                                        
                                </div>
                            </div>
                        </div>							
                    </div>					
                </div>					
            </div>
    )
  }
}
