import React, { Component } from 'react';
import { Link} from 'react-router-dom';
import Swal from 'sweetalert2';
export default class Header extends Component {
    constructor(props) {
      super(props)
    
      this.state = {}
    }
    async componentDidMount() {
        var number = "";
        number = JSON.parse(sessionStorage.getItem('Mobile'));
        const sendData = {
          Mobile: number,
          type : "details",
        }
       
        if(!number) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Wrong attempt to Login !! Please Re-Login & come back.',
          })
            .then((result) => {
              if (result.isConfirmed) {
                window.location.replace("/");
              }
            })
        }
    
      }

    Logout = () =>
    {
        Swal.fire({
            title: 'Are you sure ?',
            text: "You would be logged off & returned to the Login Page !! ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Log out!'
          }).then((result) => {
            if (result.isConfirmed) {
                sessionStorage.clear();
                window.location.replace("/");
            }
          })
    }

  render() {
    return (
        <div class="header">
			
        <div class="header-left">
            <a href="#" class="logo">
                <img src="/assets/img/Logo_Bhopal_Jamaat.png" alt="Logo"/>
            </a>
            <a href="#" class="logo logo-small">
                <img src="/assets/img/Logo_Bhopal_Jamaat.png" alt="Logo" width="30" height="30" />
            </a>
        </div>
        
        <a href="javascript:void(0);" id="toggle_btn">
            <i class="fas fa-align-left"></i>
        </a>
        
        
        <a class="mobile_btn" id="mobile_btn">
            <i class="fas fa-bars"></i>
        </a>
       
        <ul class="nav user-menu">
 
            <li class="nav-item dropdown has-arrow">
                <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
                    <span class="user-img"><img class="rounded-circle" src={"/assets/img/Account.png"} width="31" alt="Admin" /></span>
                </a>
                <div class="dropdown-menu">
                    <div class="user-header">
                        <div class="avatar avatar-sm">
                            <img src={"/assets/img/Logo_Bhopal_Jamaat.png"} alt="Admin" class="avatar-img rounded-circle" />
                        </div>
                        <div class="user-text">
                            <h6>{this.props.Name}</h6>
                            <p class="text-muted mb-0">Admin</p>
                        </div>
                    </div>
                    <Link class="dropdown-item" onClick={this.Logout} to='#'>Logout</Link>
                </div>
            </li>

            
        </ul>

        
    </div>
    )
  }
}
